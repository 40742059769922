import firebase from 'firebase'
  
const firebaseConfig = {
    apiKey: "AIzaSyCgWQeArY0eYii8v-KhCYUmQTwsob4L_QU",
    authDomain: "viedges.firebaseapp.com",
    projectId: "viedges",
    storageBucket: "viedges.appspot.com",
    messagingSenderId: "241920906482",
    appId: "1:241920906482:web:7ce0ba4085f037bf3b6c6b",
    measurementId: "G-VR5CLS94FS"
  };
  
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
  
export default storage;