import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Background from "./images/Background.webp";
import Community from "./pages/Community";
import Contact from "./pages/Contact";
import Deli from "./pages/Deli";
import Departments from "./pages/Departments";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { Image } from "react-bootstrap";
import Logo from "./images/Viedges.webp";
import Navigation from "./components/Navigation";
import ReactGA from "react-ga";
import Specials from "./pages/Specials";

const TRACKING_ID = "UA-224013108-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div style={{ backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
      <div style={{ backgroundColor: "#FFF" }}>
        <Image
          className="center d-none d-lg-block"
          src={Logo}
          style={{ width: "35vw", margin: "auto", display: "block" }}
        />
        <Image
          className="center d-lg-none"
          src={Logo}
          style={{ width: "80vw", margin: "auto", display: "block", backgroundColor: "#FFF" }}
        />
      </div>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/deli" component={Deli} />
        <Route path="/departments" component={Departments} />
        <Route path="/specials" component={Specials} />
        <Route path="/community" component={Community} />
        <Route path="/contact" component={Contact} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
