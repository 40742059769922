import { Carousel, Container, Image, Row, Spinner } from "react-bootstrap";

import Deli1 from "../images/Deli.webp";
import Deli2 from "../images/Deli1.webp";
import Deli3 from "../images/Deli2.webp";
import Deli4 from "../images/Deli3.webp";
import Deli5 from "../images/Deli4.webp";
import React from "react";

export default class Deli extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: true,
      isOpen: true,
      source: "",
      lightboxDisplay: false,
      imageToShow: "",
    };
  }

  componentDidMount() {
    var data = [Deli1, Deli2, Deli3, Deli4, Deli5];
    this.setState({ images: data, loading: false });
    console.log(data);
  }

  showImage(image) {
    this.setState({ imageToShow: image, lightboxDisplay: true });
  }

  render() {
    if (!this.state.loading) {
      return (
        <div className="p-4">
          <br />

          {/* Mobile Images */}
          <Container className="white-container d-md-none">
            <span hidden={this.state.images.length === 0}>
              <h1>Deli Menu</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              {this.state.images !== undefined &&
                this.state.images.map((val, index) => (
                  <Image onClick={() => this.showImage(val)} className="mb-2 m-1" src={val} key={index} />
                ))}
              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "100vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
          </Container>
          <Container className="white-container d-none d-md-block">
            <span hidden={this.state.images.length === 0}>
              <h1>Deli Menu</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              <Carousel variant="dark" className="mb-4">
                {this.state.images !== undefined &&
                  this.state.images.map((val, index) => (
                    <Carousel.Item key={index}>
                      <img
                        onClick={() => this.showImage(val)}
                        style={{
                          margin: "auto",
                          borderRadius: "12px",

                          width: "50%",
                          objectFit: "cover",
                        }}
                        className="d-block"
                        src={val}
                        alt="img"
                      />
                    </Carousel.Item>
                  ))}
              </Carousel>

              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "95vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
          </Container>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <Row>
            <Spinner animation="grow" variant="danger" style={{ margin: "auto" }} />
          </Row>
          <br />
        </div>
      );
    }
  }
}
