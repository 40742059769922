import { Carousel, Container, Row } from "react-bootstrap";

import Community1 from "../images/community1.webp";
import Community2 from "../images/community2.webp";
import Community3 from "../images/community3.webp";
import Community4 from "../images/community4.webp";

const Community = () => {
  return (
    <div>
      <br />
      <Container className="white-container">
        <h1>Community</h1>
        <Container>
          <Row className="justify-content-center">
            <Carousel variant="light">
              <Carousel.Item>
                <img
                  style={{
                    margin: "auto",
                    borderRadius: "12px",
                    maxHeight: "450px",
                    width: "80%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  src={Community1}
                  alt="img"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{
                    margin: "auto",
                    borderRadius: "12px",
                    maxHeight: "450px",
                    width: "80%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  src={Community2}
                  alt="img"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{
                    margin: "auto",
                    borderRadius: "12px",
                    maxHeight: "450px",
                    width: "80%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  src={Community3}
                  alt="img"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{
                    margin: "auto",
                    borderRadius: "12px",
                    maxHeight: "450px",
                    width: "80%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  src={Community4}
                  alt="img"
                />
              </Carousel.Item>
            </Carousel>
          </Row>
        </Container>
        <br />
      </Container>
      <br />
    </div>
  );
};

export default Community;
