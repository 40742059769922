import * as files from "../components/GetFiles";

import { Container, Image, Row, Spinner } from "react-bootstrap";

import React from "react";

export default class Specials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      hardware: [],
      loading: true,
      isOpen: true,
      source: "",
      lightboxDisplay: false,
      imageToShow: "",
    };
    this.getFiles();
  }

  getFiles() {
    files.GetFiles("Specials/Viedges").then((data) => {
      this.setState({ data: data, loading: false });
    });
    files.GetFiles("Specials/Buildit").then((data) => {
      this.setState({ hardware: data });
    });
  }

  showImage(image) {
    this.setState({ imageToShow: image, lightboxDisplay: true });
  }

  render() {
    if (!this.state.loading) {
      return (
        <div className="p-4">
          <br />
          <Container className="white-container d-md-none">
            <span hidden={this.state.data.length === 0}>
              <h1>Specials</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              {this.state.data.map((val, index) => (
                <Image onClick={() => this.showImage(val)} className="mb-2 m-1" src={val} key={index} />
              ))}
              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "100vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
            <span>
              <h1 hidden={this.state.hardware == null}>Build it</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              {this.state.hardware.map((val, index) => (
                <Image onClick={() => this.showImage(val)} className="mb-2 m-1" src={val} key={index} />
              ))}
              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "100vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
          </Container>
          <Container className="white-container d-none d-md-block">
            <span hidden={this.state.data.length === 0}>
              <h1>Specials</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              {this.state.data.map((val, index) => (
                <Image
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showImage(val)}
                  className="d-block w-25 mb-2 m-1"
                  src={val}
                  key={index}
                />
              ))}
              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "100vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
            <span hidden={this.state.hardware.length === 0}>
              <h1>Build it</h1>(click to enlarge image)
            </span>
            <Row className="justify-content-center">
              {this.state.hardware.map((val, index) => (
                <Image
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showImage(val)}
                  className="d-block w-25 mb-2 m-1"
                  src={val}
                  key={index}
                />
              ))}
              {this.state.lightboxDisplay ? (
                <div
                  id="lightbox"
                  className="justify-content-center p-0"
                  onClick={() => this.setState({ lightboxDisplay: false })}
                >
                  <p className="text-white text-center mb-1">(click to close)</p>
                  <Image
                    style={{ height: "100vh", width: "100%", objectFit: "contain" }}
                    className="d-block mx-auto my-auto"
                    id="lightbox-img"
                    src={this.state.imageToShow}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
          </Container>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <Row>
            <Spinner animation="grow" variant="danger" style={{ margin: "auto" }} />
          </Row>
          <br />
        </div>
      );
    }
  }
}
