import * as files from "../components/GetFiles";

import { Carousel, Container, Row, Spinner } from "react-bootstrap";

import React from "react";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], loading: true };
    this.getFiles();
  }

  getFiles() {
    files.GetFiles("Home/").then((data) => {
      this.setState({ data: data, loading: false });
    });
  }

  render() {
    return (
      <div className="home">
        <br />
        <Container className="white-container">
          <blockquote class="blockquote  p-4">
            <p style={{ textAlign: "justify" }}>
              Viedgesville was originally known as Springvale and selected by Robert Viedge in 1904 as the site of the
              first wholesale in the Transkei mainly because of its strategic position on the route of the railway to
              Umtata (Mthatha) which had not reached there yet.
              <br />
              <br />
              Robert Viedge had arrived in the Transkei as a youth of 18 in 1882 from Germany. He started trading
              initially from a wagon with his brother Fred and later Henry at Tabase &amp; later Witwatersrand at the
              start of the gold rush – this is where his trading skills stood him in good stead when he returned to the
              Transkei with a small fortune to pursue his dreams of developing the Territories.
              <br />
              <br />
              He was also heavily involved in social commitments both business and political. As the founder of the
              Transkei Civic Association he was widely regarded and often consulted as an authority of the "Native
              Question'. He also installed &amp; ran the generator for Butterworth as well as made use of motorised
              transport to relieve overgrazing of many wagon teams around the store.
              <br />
              <br />
              When he died in 1926 , Henry who had never moved from Tabase, died in 1928 which led to the sale featuring
              over a 100 properties &amp; fortunately enough where salvaged for the firm to continue until they were
              forced to sell to the XDC in the 1970's.
              <br />
              <br />A most admirable fact is that it was a policy of the Viedge brothers to take over the management of
              any trader who was badly in debt until they were back on their feet again.
            </p>
            <footer class="blockquote-footer">
              Information provided by <cite title="Source Title">TP&amp;F Member </cite>
              and a member of the famous <cite title="Source Title">Viedge Clan </cite> - [James Viedge]
            </footer>
          </blockquote>
          <Row className="justify-content-center">
            {!this.state.loading && this.state.data.length > 0 ? (
              <>
                <Carousel variant="light" className="mb-4">
                  {this.state.data.map((val) => (
                    <Carousel.Item>
                      <img
                        style={{
                          margin: "auto",
                          borderRadius: "12px",
                          maxHeight: "400px",
                          width: "80%",
                          objectFit: "cover",
                        }}
                        className="d-block"
                        src={val}
                        alt="img"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </>
            ) : (
              <>
                <div>
                  <br />
                  <Row>
                    <Spinner animation="grow" variant="danger" style={{ margin: "auto" }} />
                  </Row>
                  <br />
                </div>
              </>
            )}
          </Row>
        </Container>
        <br />
      </div>
    );
  }
}
