import storage from "../firebase";

export async function GetFiles(url) {
    var urls = [];
    const data = await storage.ref().child(url).listAll();

    let queries = [];

    data.items.forEach((item) => {
        queries.push(item.getDownloadURL());
    });

    urls = await Promise.all(queries);

    return urls;
}