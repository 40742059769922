import { Carousel, Col, Container, Image, Row, Table } from "react-bootstrap";

import Fuel from "../images/Fuel.webp";
import Fuel1 from "../images/Fuel1.webp";
import Fuel2 from "../images/Fuel2.webp";
import Hardware from "../images/Hardware.webp";
import Hardware1 from "../images/Hardware1.webp";
import Hardware2 from "../images/Hardware2.webp";
import Liquor from "../images/Liquor.webp";
import Liquor1 from "../images/Liquor1.webp";
import Liquor2 from "../images/Liquor2.webp";
import Liquor3 from "../images/Liquor3.webp";
import Wholesale from "../images/Wholesale.webp";
import Wholesale1 from "../images/Wholesale1.webp";
import Wholesale2 from "../images/Wholesale2.webp";
import Wholesale3 from "../images/Wholesale3.webp";

const Departments = () => {
  console.log(Fuel1);
  return (
    <div>
      <Container className="d-xl-none">
        <br />
        <Row className="justify-content-center" id="wholesale">
          <Col xs={12} sm={12} md={12} lg={12} className="my-auto mb-1">
            <Container className="white-container p-3">
              <h2>Wholesale</h2>
              <p style={{ textAlign: "justify" }}>
                Viedgesville Wholesalers stocks a huge variety of products ranging from food to feeds, appliances to
                furniture, cosmetics to consumables and so much more. Whether you’re stocking up your shop or buying
                your groceries – we provide quality for your satisfaction, affordability to stretch your budget &amp;
                all-in-one convenience to save you time! Let Viedges stock your cupboards!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} className="order-2">
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale1} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image Wholesale2={{ borderRadius: "12px" }} src={Wholesale2} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale3} width="100%" alt="Wholesale" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="hardware">
          <Col xs={12} sm={12} md={12} lg={12} className="my-auto mb-1">
            <Container className="white-container p-3">
              <h2>Build it</h2>
              <p style={{ textAlign: "justify" }}>
                Got a hardware project? You know the drill – bolt on over to Viedges Build It &amp; go nuts! We stock
                anything from DIY to home improvements &amp; building materials. Our great prices will suit any DIY
                enthusiast, home owner, small business or large contractor &amp; your finished project will have
                everyone agreeing you “Nailed it!”. Let Viedges help you build your future!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8}>
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware} width="100%" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware1} width="100%" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware2} width="100%" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="fuel">
          <Col xs={12} sm={12} md={12} lg={12} className="my-auto mb-1">
            <Container className="white-container p-3">
              <h2>Fuel</h2>
              <p style={{ textAlign: "justify" }}>
                When you see the bright yellow fuel station with the “Viedges” logo, it’s time to pull in for a fill up,
                top up, pump up and more - keep an eye out for our convenience store coming soon! Our hygienic
                pay-restrooms ensure a sanitary experience for your journey ahead. For your vehicles, your passengers or
                yourself - let Viedges fuel your journey!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Sun:</td>
                    <td>6:00 – 19:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8}>
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel} width="100%" alt="Fuel" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel1} width="100%" alt="Fuel" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel2} width="100%" alt="Fuel" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="liquor">
          <Col xs={12} sm={12} md={12} lg={12} className="my-auto mb-1">
            <Container className="white-container p-3">
              <h2>Liquor</h2>
              <p style={{ textAlign: "justify" }}>
                At Viedges Liquor we know South Africans enjoy a hearty celebration or a crisp refresher after a long
                day which is why we stock an amazing range of the finest spirits, beers, wines and other beverages for
                any occasion at competitive prices that will quench any thirst! Let Viedges start your party!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8}>
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor1} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor2} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor3} width="100%" alt="Liquor" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
      <Container className="d-none d-xl-block">
        <br />
        <Row className="justify-content-center" id="wholesale">
          <Col
            style={{ marginRight: "-50px" }}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="my-auto order-sm-2 order-xs-2 order-md-1 order-lg-1 order-xl-1 order-2"
          >
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale1} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image Wholesale2={{ borderRadius: "12px" }} src={Wholesale2} width="100%" alt="Wholesale" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Wholesale3} width="100%" alt="Wholesale" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
          <Col
            style={{ marginLeft: "-50px", zIndex: "1" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="order-sm-1 order-xs-1 order-md-2 order-lg-2 order-xl-2 order-1 my-auto"
          >
            <Container className="white-container p-3">
              <h2>Wholesale</h2>
              <p style={{ textAlign: "justify" }}>
                Viedgesville Wholesalers stocks a huge variety of products ranging from food to feeds, appliances to
                furniture, cosmetics to consumables and so much more. <br />
                <br />
                Whether you’re stocking up your shop or buying your groceries – we provide quality for your
                satisfaction, affordability to stretch your budget &amp; all-in-one convenience to save you time! <br />
                <br />
                Let Viedges stock your cupboards !
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="hardware">
          <Col style={{ marginRight: "-50px", zIndex: "1" }} xs={12} sm={12} md={4} lg={4} xl={4} className="my-auto">
            <Container className="white-container p-3">
              <h2>Build it</h2>
              <p style={{ textAlign: "justify" }}>
                Got a hardware project?
                <br />
                <br />
                You know the drill – bolt on over to Viedges Build It &amp; go nuts!
                <br />
                <br />
                We stock anything from DIY to home improvements &amp; building materials. Our great prices will suit any
                DIY enthusiast, home owner, small business or large contractor &amp; your finished project will have
                everyone agreeing you “Nailed it!”.
                <br />
                <br />
                Let Viedges help you build your future!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col className="my-auto" style={{ marginLeft: "-50px" }} xs={12} sm={12} md={6} lg={6} xl={6}>
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware} width="100%" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware1} width="100%" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Hardware2} width="100%" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="fuel">
          <Col
            style={{ marginRight: "-50px" }}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="my-auto order-sm-2 order-xs-2 order-md-1 order-lg-1 order-xl-1 order-2"
          >
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel} width="100%" alt="Fuel" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel1} width="100%" alt="Fuel" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Fuel2} width="100%" alt="Fuel" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
          <Col
            style={{ marginLeft: "-50px", zIndex: "1" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="order-sm-1 order-xs-1 order-md-2 order-lg-2 order-xl-2 order-1 my-auto"
          >
            <Container className="white-container p-3">
              <h2>Fuel</h2>
              <p style={{ textAlign: "justify" }}>
                When you see the bright yellow fuel station with the “Viedges” logo, it’s time to pull in for a fill up,
                top up, pump up and more - keep an eye out for our convenience store coming soon!
                <br />
                <br />
                Our hygienic pay-restrooms ensure a sanitary experience for your journey ahead.
                <br />
                <br />
                For your vehicles, your passengers or yourself - let Viedges fuel your journey!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Sun:</td>
                    <td>6:00 – 19:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center" id="liquor">
          <Col style={{ marginRight: "-50px", zIndex: "1" }} xs={12} sm={12} md={4} lg={4} xl={4} className="my-auto">
            <Container className="white-container p-3">
              <h2>Liquor</h2>
              <p style={{ textAlign: "justify" }}>
                At Viedges Liquor we know South Africans enjoy a hearty celebration or a crisp refresher after a long
                day which is why we stock an amazing range of the finest spirits, beers, wines and other beverages for
                any occasion at competitive prices that will quench any thirst!
                <br />
                <br />
                Let Viedges start your party!
              </p>
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td>Mon to Fri:</td>
                    <td>07:30 – 17:00</td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td> 07:00 – 13:00</td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td>08:00 – 13:00</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col className="my-auto" style={{ marginLeft: "-50px" }} xs={12} sm={12} md={6} lg={6} xl={6}>
            <div style={styles.imageCenter}>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor1} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor2} width="100%" alt="Liquor" />
                </Carousel.Item>
                <Carousel.Item>
                  <Image style={{ borderRadius: "12px" }} src={Liquor3} width="100%" alt="Liquor" />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default Departments;

const styles = {
  imageCenter: {
    display: "flex",
    justifyContent: "center",
  },
};
