import { Navbar, Row, Col, Image, Container } from 'react-bootstrap';
import Build from '../images/Build.png';
import Fuel from '../images/Fuel.png';
import React from "react";
import Liquor from '../images/Liquor.png';
import Wholesale from '../images/Wholesale.png';

export default class Footer extends React.Component {

    clickScroll(id) {
        const element = document.getElementById(id);
        element.scrollTo(0, element.offsetTop);
    }

    render() {
        return (
            <div>
                <Navbar
                    className='footer'
                    style={{ backgroundColor: "#32348B", color: "#fff" }}
                >
                    <Container>
                        <Row className='mx-auto' style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>

                                <Col xl lg md sm xs className='center-block' style={{ textAlign: 'center' }}>
                                    <a className='FooterLink' href="/">Home</a>
                                </Col>
                                <Col xl lg md sm xs className='center-block' style={{ textAlign: 'center' }}>
                                    <a className='FooterLink' href="/departments">Departments</a>
                                </Col>
                                <Col xl lg md sm xs className='center-block' style={{ textAlign: 'center' }}>
                                    <a className='FooterLink' href="/specials">Specials</a>
                                </Col>
                                <Col xl lg md sm xs className='center-block' style={{ textAlign: 'center' }}>
                                    <a className='FooterLink' href="/community">Community</a>
                                </Col>
                                <Col xl lg md sm xs className='center-block' style={{ textAlign: 'center' }}>
                                    <a className='FooterLink' href="/contact">Contact Us</a>
                                </Col>

                            </Row>
                            <Row style={{ width: '100%' }}>
                                <Col className='center-block' style={{ textAlign: 'center' }}>
                                    <a href="/departments/#hardware"><Image src={Build} className='mx-2 mt-2'></Image></a>
                                    <a href="/departments/#fuel"><Image src={Fuel} className='mx-2 mt-2'></Image></a>
                                    <a href="/departments/#liquor"><Image src={Liquor} className='mx-2 mt-2'></Image></a>
                                    <a href="/departments/#wholesale"><Image src={Wholesale} className='mx-2 mt-2'></Image></a>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Navbar>
            </div>
        );
    }
}