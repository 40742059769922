import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { EnvelopeFill, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";

import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            mobile: "",
            query: "",
            loading: false,
            isVerified: false
        };
        this.handleOnchange = this.handleOnchange.bind(this);
    }

    handleOnchange(value) {
        this.setState({ isVerified: true })
    }

    handleChange = (e) => {
        if (e.target.name === "name") {
            this.setState({ name: e.target.value.trim() });
        }
        if (e.target.name === "email") {
            this.setState({ email: e.target.value.trim() });
        }
        if (e.target.name === "mobile") {
            this.setState({ mobile: e.target.value.trim() });
        }
        if (e.target.name === "query") {
            this.setState({ query: e.target.value.trim() });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault()
        const templateId = 'template_g8t0v32';
        const serviceID = "service_y567ooc";
        this.sendFeedback(serviceID, templateId, { from_name: this.state.name, mobile: this.state.mobile, message_html: this.state.query, email: this.state.email })
    };

    sendFeedback = (serviceID, templateId, variables) => {
        if (this.state.name !== "" && this.state.mobile !== "" && this.state.query !== "" && this.state.isVerified) {
            this.setState({ loading: true });
            window.emailjs.send(
                serviceID, templateId,
                variables
            ).then(res => {
                this.setState({ loading: false });
                alert(`Thank you for your message. Your query has been forwarded.`);
            })
                .catch(err => console.error('There has been an Error.', err));
        }
        else {
            alert(`Please enter all required fields and check the Recaptcha`);
            this.setState({ loading: false });
        }
    }

    renderSpinner = () => {
        if (!this.state.loading) {
            return (
                <Button disabled={!this.state.isVerified} variant="danger" type="submit" 
                onClick={this.handleSubmit} style={{ display: 'block', margin: 'auto' }}>
                    {!this.state.loading ? 'Submit' : 'Loading...'}
                </Button>
            );
        }
        else {
            return (
                <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{ display: 'block', margin: 'auto' }}
                ></Spinner>
            );
        }
    }

    render() {
        return (
            <div>
                <br />
                <Container className="white-container">
                    <Row className='p-3'>
                        <Col xl={5} lg={5}>
                            <Row>
                                <Col>
                                    <h3>Contact</h3>
                                    <span><GeoAltFill style={{ fontSize: "20px" }} />&nbsp;Main Coffeebay Road, Jojweni</span>
                                    <br />
                                    <br />
                                    <span><TelephoneFill style={{ fontSize: "20px" }} />&nbsp;Viedgesville Wholesalers PTY LTD: <a href="tel:0475380017">047 538 0017 / 8</a></span>
                                    <br />
                                    <br />
                                    <span><TelephoneFill style={{ fontSize: "20px" }} />&nbsp;Viedgesville Mquanduli Superstore PTY LTD: <a href="tel:0475731015">047 573 1015</a></span>
                                    <br />
                                    <br />
                                    <EnvelopeFill style={{ fontSize: "20px" }} />&nbsp;<a href="mailto:admin@viedges.co.za">admin@viedges.co.za</a>
                                    <br />
                                    <br />
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col>
                                    <h3>Location</h3>
                                    <div className="resp-container">
                                        <iframe className="resp-iframe" title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3393.708528385322!2d28.690688015110734!3d-31.723856081302742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e5fd53e40000001%3A0x442b0117c21e94bc!2sViedgesville%20Wholesalers%20(Pty)%20Ltd!5e0!3m2!1sen!2sza!4v1644245698670!5m2!1sen!2sza" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy"></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h3>Contact Form</h3>
                            <Form>
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control onChange={this.handleChange} name="name" type="name" placeholder="Name" isInvalid={this.state.name === ""} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control onChange={this.handleChange} name="email" type="email" placeholder="Enter email"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridMobile">
                                    <Form.Label>Phone:</Form.Label>
                                    <Form.Control onChange={this.handleChange} name="mobile" placeholder="Enter Mobile Number" isInvalid={this.state.mobile === ""} />
                                </Form.Group>
                                <Form.Group as={Col} id="formGridQuery">
                                    <Form.Label>Message:</Form.Label>
                                    <Form.Control onChange={this.handleChange} name="query" as="textarea" rows={2} isInvalid={this.state.query === ""} />
                                </Form.Group>
                                <br />
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={this.handleOnchange}
                                />
                                <br />
                                {this.renderSpinner()}
                            </Form >
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
};
