import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  return (
    <Navbar className="p-0" collapseOnSelect expand="lg" style={{ backgroundColor: "#32348B" }}>
      <Container>
        <Navbar.Toggle className="m-auto" aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto mx-auto parent align-items-center">
            <NavLink
              className="nav-link"
              to="/"
              style={{
                color: "#fff",
                ...(location.pathname === "/" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Home</h5>
            </NavLink>
            <NavLink
              className="nav-link"
              to="/deli"
              style={{
                color: "#fff",
                ...(location.pathname === "/deli" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Deli Menu</h5>
            </NavLink>
            <NavLink
              className="nav-link"
              to="/departments"
              style={{
                color: "#fff",
                ...(location.pathname === "/departments" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Departments</h5>
            </NavLink>

            <NavLink
              className="nav-link"
              to="/specials"
              style={{
                color: "#fff",
                ...(location.pathname === "/specials" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Specials</h5>
            </NavLink>
            <NavLink
              className="nav-link"
              to="/community"
              style={{
                color: "#fff",
                ...(location.pathname === "/community" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Community</h5>
            </NavLink>
            <NavLink
              className="nav-link"
              to="/contact"
              style={{
                color: "#fff",
                ...(location.pathname === "/contact" ? { backgroundColor: "#C72229" } : {}),
              }}
            >
              <h5 className="no-margin">Contact Us</h5>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Navigation;
